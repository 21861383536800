import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const AiotRouter: Array<RouteRecordRaw> = [
  {
    path: '/aiotInternet',
    component: Layout,
    redirect: '/aiotInternet/intelligentLock',
    meta: {
      title: 'AIOT物联网',
      icon: '#iconexample',
      alwaysShow: true // will always show the root menu
    },
    children: [
      {
        path: 'intelligentLock',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiotInternet/intelligentLock.vue'),
        name: 'intelligentLock',
        meta: {
          title: '智能锁管理'
        }
      },
      {
        path: 'facemanageDevice',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiotInternet/facemanageDevice.vue'),
        name: 'facemanageDevice',
        meta: {
          title: '设备人脸管理'
        }
      },
      {
        path: 'deviceLog',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiotInternet/deviceLog.vue'),
        name: 'deviceLog',
        meta: {
          title: '设备日志'
        }
      }
    ]
  }
]

export default AiotRouter
