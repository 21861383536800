/*
 * @Description: app mutations type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-23 10:38:43
 */

export enum AppMutationTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
  TOGGLE_DEVICE = 'TOGGLE_DEVICE',
  // SET_LANGUAGE = 'SET_LANGUAGE',
  SET_SIZE = 'SET_SIZE',
}
