/*
 * @Description: 用户相关接口
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users, UserUpdate, Excel } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'

export const loginRequest = (userInfo: RequestParams) => {
  return https(true).request<RootObject<LoginModel>>('/backstage/api/login', Method.POST, userInfo, ContentType.json)
}

export const userInfoRequest = () => {
  return https(true).request<RootObject<UserInfoModel>>('/backstage/api/user/', Method.GET, undefined, ContentType.form)
}

export const getUsers = (user: any) => {
  return https(true).request<RootObject<Users>>('users/', Method.GET, user, ContentType.form)
}
export const faceLogin = (user: any) => {
  return https(true).request<RootObject<UserInfoModel>>('/backstage/api/facelogin', Method.POST, user, ContentType.json)
}
export const updateUser = (user: any) => {
  return https(true).request<RootObject<UserUpdate>>('user/', Method.POST, user, ContentType.json)
}
export const allRoles = () => {
  return https(true).request<RootObject<Users>>('role/', Method.GET, undefined, ContentType.form)
}

// 所有用户和管理员
export const addManage = (user: any) => {
  return https(true).request<RootObject<Users>>('manageusers/', Method.POST, user, ContentType.json)
}
export const updateStatus = (user: any) => {
  return https(true).request<RootObject<Users>>('role/', Method.PUT, user, ContentType.form)
}
export const allManageUsers = () => {
  return https(true).request<RootObject<Users>>('manageusers/', Method.GET, undefined, ContentType.form)
}
export const updateManage = (user: any) => {
  return https(true).request<RootObject<Users>>('manageusers/', Method.PUT, user, ContentType.form)
}
export const deleteManage = (user: any) => {
  return https(true).request<RootObject<Users>>('manageusers/', Method.DELETE, user, ContentType.form)
}
export const uploadImage = (user: any) => {
  return https(true).request<RootObject<Users>>('user/uploadsfaces', Method.POST, user, ContentType.multipart)
}
export const allDept = () => {
  return https(true).request<RootObject<Users>>('dept/', Method.GET, undefined, ContentType.form)
}
export const addDept = (user: any) => {
  return https(true).request<RootObject<Users>>('dept/', Method.POST, user, ContentType.json)
}
export const updateStatuDept = (user: any) => {
  return https(true).request<RootObject<Users>>('dept/', Method.PUT, user, ContentType.form)
}
export const deleteDept = (user: any) => {
  return https(true).request<RootObject<Users>>('dept/', Method.DELETE, user, ContentType.form)
}

// 操作记录
export const operatingRecord = (user: any) => {
  return https(true).request<RootObject<Users>>('logging/', Method.GET, user, ContentType.form)
}

// 考勤管理
export const attendanceRecord = (data: object) => {
  return https(true).request<RootObject<Users>>('worklogs/', Method.POST, data, ContentType.json)
}
export const attendanceExcel = (data: object) => {
  return https(true).request<RootObject<Excel>>('worklogs/', Method.GET, data, ContentType.form)
}
export const attendanceRuleSet = (data: object) => {
  return https(true).request<RootObject<Users>>('workrule/', Method.POST, data, ContentType.json)
}
export const attendanceRuleAll = () => {
  return https(true).request<RootObject<Users>>('workrule/', Method.GET, undefined, ContentType.form)
}
