import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const SmartLockRouter: Array<RouteRecordRaw> = [
  {
    path: '/smartLock',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'smartLockManage',
        component: () => import(/* webpackChunkName: "smartLock" */ '@/views/smartLock/smartLockManage.vue'),
        name: 'smartLockManage',
        meta: {
          title: '智能门锁',
          icon: '#iconhuanfu'
        }
      }
    ]
  }
]
export default SmartLockRouter
