/*
 * @Description:
 * @Author: ZY
 * @Date: 2021-01-08 19:32:52
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-23 15:55:22
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const permissionRouter: Array<RouteRecordRaw> = [
  {
    path: '/permission',
    component: Layout,
    redirect: '/permission/directive',
    meta: {
      title: '人脸登录',
      icon: '#iconquanxian',
      roles: ['超级管理员', '管理员'], // you can set roles in root nav
      alwaysShow: true // will always show the root menu
    },
    children: [
      {
        path: 'user',
        component: () => import(/** webpackChunkName: "permisssion-user" */ '@/views/permission/role/user.vue'),
        name: 'userManage',
        meta: {
          title: '企业用户管理',
          roles: ['超级管理员', '管理员']
        }
      },
      {
        path: 'dept',
        component: () => import(/** webpackChunkName: "permisssion-user" */ '@/views/permission/role/Department.vue'),
        name: 'department',
        meta: {
          title: '企业管理'
        }
      },
      {
        path: 'role',
        component: () => import(/* webpackChunkName: "permission-role" */ '@/views/permission/role/Role.vue'),
        name: 'RolePermission',
        meta: {
          title: '企业角色管理',
          roles: ['超级管理员']
        }
      },
      {
        path: 'attendance',
        component: () => import(/* webpackChunkName: "permission-role" */ '@/views/permission/role/attendance.vue'),
        name: 'workRttendance',
        meta: {
          title: '考勤管理',
          roles: ['超级管理员']
        }
      },
      {
        path: 'operating',
        component: () => import(/** webpackChunkName: "permisssion-user" */ '@/views/permission/role/Operatingrecord.vue'),
        name: 'operatingRecord',
        meta: {
          title: '操作日志记录'
        }
      },
      {
        path: 'dynamicnews',
        component: () => import(/** webpackChunkName: "permisssion-user" */ '@/views/permission/role/dynamicnews.vue'),
        name: 'dynamicnews',
        meta: {
          title: '动态新闻'
        }
      }
    ]
  }
]
export default permissionRouter
