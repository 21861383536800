import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const AiFaceouter: Array<RouteRecordRaw> = [
  {
    path: '/aiface',
    component: Layout,
    redirect: '/aiface/photoManage',
    name: 'AiFace',
    meta: {
      title: 'AI文娱',
      icon: '#iconnested',
      roles: ['超级管理员', '管理员']
    },
    children: [
      {
        path: 'photoManage',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiface/photoManage.vue'),
        name: 'photoManage',
        meta: {
          title: '底图管理',
          roles: ['超级管理员']
        }
      },
      {
        path: 'wxusers',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiface/wxusers.vue'),
        name: 'wxusers',
        meta: {
          title: '微信用户管理',
          roles: ['超级管理员']
        }
      },
      {
        path: 'orderRecord',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiface/orderRecord.vue'),
        name: 'orderRecord',
        meta: {
          title: '订单支付记录',
          roles: ['超级管理员']
        }
      },
      {
        path: 'updatePhotorecord',
        component: () => import(/* webpackChunkName: "theme" */ '@/views/aiface/updatePhotorecord.vue'),
        name: 'updatePhotorecord',
        meta: {
          title: '换脸记录',
          roles: ['超级管理员']
        }
      }
    ]
  }
]

export default AiFaceouter
